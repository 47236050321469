// EventsPage.jsx

import React, { useState } from 'react';
import { Box, useMediaQuery, Typography, Button, useTheme, Link, List, ListItem } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import Posts from 'scenes/widgets/PlaceholderPostsWidget.jsx'
import { Link as RouterLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Countdown from 'react-countdown';

const EventsPage = () => {
  const theme = useTheme();
  const [sectionIndex, setSectionIndex] = useState(0);
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');

  const handleShowMore = () => {
    setSectionIndex((prevIndex) => prevIndex + 1);
  };

  const sections = [
    {
      title: 'Kunst, Kaffee & Mehr – Die Themen-Events des Kunstkaleidoskop-Projekts',
      content: `
Unsere Kunst, Kaffee & Mehr-Events sind wöchentliche Themenevents, die Kunst, Bildung und Wirtschaft in einem einzigartigen Rahmen vereinen. Jedes Event widmet sich einem bestimmten Thema, das die Inhalte und den Charakter der Veranstaltung prägt. Von Sport über Kulinarik bis hin zu Kunstworkshops – diese Events bieten für alle Beteiligten wertvolle Möglichkeiten und Vorteile.
      `,
    },
    {
      title: 'Start der Themen-Events 2025',
      content: `
Im Jahr 2025 beginnt die Veranstaltungsreihe Kunst, Kaffee & Mehr offiziell. Wir fokussieren uns zunächst auf Partnerschaften mit Gastronomiebetrieben und Event-Locations wie Restaurants und Cafés, um diese Standorte zu nutzen und Zeiten zu füllen, die oft als „tote Zeiten“ gelten. Durch die Events können Gastronomen und Eventbetreiber so zusätzliche Gäste anziehen und ihr Haus zu weniger frequentierten Zeiten beleben.

Unternehmen können sich jetzt bewerben und sich ihre bevorzugten Kalenderwochen für 2025 sichern, in denen ihr Event exklusiv stattfindet – pro Kalenderwoche wird maximal ein Termin vergeben. Einige Wochen sind bereits vorreserviert, doch in der Startphase bieten wir die gesamte Vertriebspower unserer Agenten kostenfrei an. Der erste Samstag im Monat ist bereits festgelegt: An diesem Tag findet unser eigenes „Kunst, Kaffee und Mehr“-Event immer in der Kunstkaleidoskop-Galerie statt. Die weiteren Wochen können frei belegt werden.
      `,
    },
    {
      title: 'Unterstützung durch unsere Agenten und Partner',
      content: `
Dank der Kooperation mit den Snookerfy-Agenten steht unseren ersten Partnern ein umfassendes Support-Team zur Verfügung. In der Startphase stellen wir unsere Snookerfy-Agenten den Veranstaltern kostenfrei bereit – vom Marketing über die Organisation bis hin zur Durchführung übernehmen die Agenten die gesamte Eventbegleitung. So wird es für Unternehmen einfach, ein Kunst, Kaffee & Mehr-Event zu gestalten und von unserer Expertise zu profitieren.

Unser erster Gastronomie-Partner ist das Wave City in der HafenCity, das 2025 als erste Eventlocation unser Konzept umsetzen wird und alle Dienstleistungen der Snookerfy-Agenten kostenfrei in Anspruch nehmen kann. Diese exklusive Möglichkeit bietet unseren ersten Partnern maximale Unterstützung und sichert ihnen die komplette Begleitung ihrer Veranstaltung.

Bald wird es auf unserer Webseite eine Übersicht aller geplanten Events geben, in der Sie auf einen Blick sehen, welche Woche bereits belegt ist und welche Wochen noch verfügbar sind.
      `,
    },
    {
      title: 'Vorteile der Themen-Events für alle Beteiligten',
      content: `
**Für Veranstalter: Eine starke Plattform zur Sichtbarkeit und Kundenbindung**

Als Veranstalter eines Kunst, Kaffee & Mehr-Events haben Sie die Möglichkeit, Ihr Unternehmen und Ihre Angebote einem neuen Publikum vorzustellen und dabei das Eventthema frei zu wählen. Unsere Agenten kümmern sich um alle organisatorischen Details, damit Sie sich auf die Gestaltung und Ausführung des Programms konzentrieren können. Das Event zieht neue Gäste an und belebt Ihre Räume in weniger frequentierten Zeiten – eine ideale Gelegenheit, Ihre Marke zu stärken und neue Kunden zu gewinnen.

Ihre Vorteile im Überblick:

- **Maximale Flexibilität:** Bestimmen Sie das Thema und den Charakter Ihres Events.
- **Komplette Organisation:** Dank der Unterstützung unserer Snookerfy-Agenten übernehmen wir die Planung und Durchführung des Events.
- **Erweiterte Reichweite:** Profitieren Sie von unserer Plattform, die Ihr Event bewirbt und neue Gäste anzieht.
- **Exklusivität:** Pro Woche wird nur ein Event vergeben, und unser Marketing unterstützt Sie kostenfrei.

**Für Schüler: Eine kreative Bühne und wertvolle Erfahrungen**

Die Kunst, Kaffee & Mehr-Events bieten den teilnehmenden Schülern eine inspirierende Plattform, um ihre Kunstkaleidoskope vor Ort zu präsentieren. Neben der Wertschätzung ihrer Werke erhalten sie durch das Event eine Dividende, die aus Spenden des Abends finanziert wird und unter allen anwesenden Schülern fair verteilt wird. Darüber hinaus können sie erste Kontakte zur Wirtschaft knüpfen und Einblicke in die kreative und unternehmerische Welt gewinnen.

Ihre Vorteile im Überblick:

- **Bühne für ihre Kunst:** Schüler präsentieren ihre Kunstkaleidoskope und erhalten direkte Anerkennung.
- **Finanzielle Wertschätzung:** Eine Dividende aus Spendengeldern honoriert ihre Teilnahme.
- **Erfahrung und Netzwerke:** Die Schüler sammeln wertvolle Erfahrungen und knüpfen erste Kontakte zu Unternehmen und Förderern.
- **Interaktive Themenwelten:** Durch das Eventthema können die Schüler neue Erfahrungen sammeln, sei es durch sportliche Aktivitäten, kreative Workshops oder Verkostungen.

**Für Unternehmen und Werbepartner: Exklusive Sichtbarkeit und Kundengewinnung**

Unternehmen und Werbepartner können bei Kunst, Kaffee & Mehr-Events ihre Produkte und Dienstleistungen in einem kreativen, thematischen Umfeld präsentieren. Diese einzigartige Plattform bietet exklusive Präsenz bei einem kunstinteressierten Publikum und gibt Ihnen die Möglichkeit, direkt mit potenziellen Kunden und zukünftigen Fachkräften in Kontakt zu treten. Sie können exklusive Rabatte und Angebote für Wochensieger-Kaleidoskope schaffen, die auf der Anzahl der gesammelten Likes basieren, und so für Ihre Marke nachhaltig Aufmerksamkeit erzeugen.

Ihre Vorteile im Überblick:

- **Gezielte Markenpräsenz:** Präsentieren Sie Ihre Marke, Produkte und Dienstleistungen in einem inspirierten Rahmen.
- **Exklusiver Zugang zur Zielgruppe:** Schulen, Schüler und kunstinteressierte Gäste sind vor Ort – ideal, um neue Kunden und künftige Fachkräfte zu erreichen.
- **Individuelle Kundenangebote:** Unternehmen können spezielle Vorteile und Rabatte für Kaleidoskop-Wochensieger bieten und damit Kundentreue aufbauen.
      `,
    },
    {
      title: 'Persönliche Beratung vor Ort',
      content: `
Um alle weiteren Informationen und Details zur Teilnahme an den Kunst, Kaffee & Mehr-Events zu erhalten, stehen wir Ihnen gerne zur Verfügung. Besuchen Sie uns in der Kunstkaleidoskop-Galerie, jeden Freitag zwischen 10 und 16 Uhr, um sich vor Ort über das Projekt und Ihre Teilnahme als Veranstalter oder Partner zu informieren.
      `,
    },
  ];

  const currentSections = sections.slice(0, sectionIndex + 1);

    // Countdown renderer
    const countdownRenderer = ({ days, hours, minutes, seconds }) => {
      return (
        <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
          {days} Tage {hours} Stunden {minutes} Minuten {seconds} Sekunden
        </Typography>
      );
    };
  
    // Target date: January 1st, 2025, at 00:00 Uhr
    const targetDate = new Date('2025-01-01T00:00:00');

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: '20px',
          maxWidth: '800px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom>
          Diese Seite befindet sich derzeit im Aufbau.
        </Typography>

                {/* Countdown */}
                <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
          Countdown zum Start der Events
          </Typography>
          <Countdown date={targetDate} renderer={countdownRenderer} />
        </Box>
      </Box>

      <Box
  sx={{
    padding: '20px',
    maxWidth: '1000px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '20px',
  }}
>
  {currentSections.map((section, index) => (
    <Box key={index} sx={{ marginBottom: '20px' }}>
      {section.title && (
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
          {section.title}
        </Typography>
      )}
      {section.content && (
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <Typography variant="body1" sx={{ fontSize: '1rem' }} paragraph {...props} />
            ),
            strong: ({ node, ...props }) => <strong {...props} />,
            em: ({ node, ...props }) => <em {...props} />,
            ul: ({ node, ...props }) => (
              <List
                sx={{
                  paddingLeft: '1em',
                  marginTop: 0,
                  marginBottom: 0,
                }}
                {...props}
              />
            ),
            li: ({ node, ...props }) => (
              <ListItem
                sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}
                disableGutters
                disablePadding
              >
                <Typography variant="body1" sx={{ fontSize: '1rem' }} component="span" {...props} />
              </ListItem>
            ),
          }}
        >
          {section.content}
        </ReactMarkdown>
      )}
      {index < sections.length - 1 && index === sectionIndex && (
        <Box textAlign="center">
          <Button variant="text" onClick={handleShowMore} sx={{ marginTop: '10px' }}>
            Mehr erfahren
          </Button>
        </Box>
      )}
    </Box>
  ))}
</Box>

      <Box
        width="100%"
        padding="0rem 6%"
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap="0.5rem"
        justifyContent="center">
                  <Box
          flexBasis={isNonMobileScreens ? '75%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
        >
              <Posts></Posts>
              </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default EventsPage;
