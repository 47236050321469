// UnterstützerPage.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
} from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import ReactMarkdown from 'react-markdown';
import Countdown from 'react-countdown';

const UnterstutzerPage = () => {
  const theme = useTheme();
  const [activeContent, setActiveContent] = useState(null);

  const handleMehrErfahren = (type) => {
    setActiveContent((prevType) => (prevType === type ? null : type));
  };

  // Text content for Bürger and Unternehmen
  const contentData = {
    buerger: {
      title: 'Für Privatperson',
      content: `
Sie möchten einen Beitrag leisten, um Schulkindern im Großraum Hamburg die Chance zu geben, sich kreativ zu entfalten, soziale Kompetenzen zu entwickeln und gleichzeitig erste Kontakte zur Wirtschaft zu knüpfen? Mit einer Spende auf dieser Seite können Sie genau das erreichen.

**Warum unterstützen?**

Unsere Vision ist es, Kinder nicht nur digital zu vernetzen, sondern echte Verbindungen zu schaffen. In einer Zeit, in der viele Kinder von digitalen Medien geprägt sind, möchten wir ihnen zeigen, wie wertvoll echte Begegnungen sind. Indem Sie hier spenden, unterstützen Sie, dass Kinder den Weg ins echte Leben finden, weg vom Bildschirm und hinein in kreative Projekte. Sie fördern ein Konzept, bei dem Kinder nicht nur lernen, wie sie ein Kunstkaleidoskop-Rohling gestalten, sondern auch, wie sie dabei eigenständig Entscheidungen treffen und ihre Werke vor Publikum präsentieren. So lernen sie von Anfang an wichtige soziale Kompetenzen – wie z. B. anderen in die Augen zu schauen, mit anderen in Dialog zu treten und gemeinsam ihre Werke zu feiern.

**Das Ziel**

Mit jeder Spende helfen Sie uns, unser Ziel zu erreichen: dass alle Schulkinder im Großraum Hamburg im Jahr 2025 kostenlos am Kunstkaleidoskop-Wettbewerb teilnehmen können. Dafür benötigen wir 9,90 Euro pro Kind, was bei 300.000 Schülern eine Summe von etwa 3 Millionen Euro ergibt. Jede Spende – ob ein Euro oder mehr – bringt uns näher an dieses Ziel.

**So können Sie mitmachen**

- **Spende hier:** Jeder Cent hilft. Ab 9,90 Euro ermöglichen Sie einem Kind die Teilnahme am Wettbewerb.
- **Vor Ort in der Galerie:** Wenn Sie möchten, können Sie uns auch persönlich besuchen. Kommen Sie gern freitags zwischen 10 und 16 Uhr in unsere Galerie in Cremon 11, 20457 Hamburg. Dort können Sie sich über das Projekt informieren und Rohlinge direkt vor Ort erwerben. Alternativ bieten unsere Partnerbetriebe ebenfalls Kunstkaleidoskop-Rohlinge zum Kauf an.

**Für Kinder – Was passiert mit einem geschenkten Rohling?**

Wenn Sie einem Kind einen Kunstkaleidoskop-Rohling schenken, beginnt für das Kind ein kreativer und spannender Prozess:

1. **Rohling gestalten:** Das Kind kann ab 3 Jahren beginnen, den Rohling eigenständig zu bestücken – mit einer Füllung eigener Wahl, die es anschließend bemalt.
2. **Digitalisieren und einreichen:** Nachdem das Kunstkaleidoskop gestaltet ist, wird es digitalisiert. Die Eltern oder Erziehungsberechtigten fotografieren das Kunstwerk und erstellen ein kurzes Video (max. 15 Sekunden). Vor dem Startdatum 1. Januar 2025 reichen sie die Dokumente per E-Mail bei uns ein.
3. **Künstlername festlegen:** Der junge Produzent darf einen eigenen Künstlernamen wählen, unter dem sein Kunstkaleidoskop veröffentlicht wird. Hier ist die Erlaubnis eines Erziehungsberechtigten erforderlich.
4. **Teilnahme am Wettbewerb:** Sobald das Kunstkaleidoskop eingereicht ist, wird es nach Prüfung veröffentlicht und nimmt am Wettbewerb teil.

Mit Ihrer Unterstützung fördern Sie also nicht nur die Kreativität eines Kindes, sondern begleiten es auf einem Weg, der wertvolle soziale Erfahrungen und erste Berührungspunkte zur Wirtschaft schafft.

**Unsere Partnerschaft mit Snuggify**

Wir arbeiten eng mit Snuggify zusammen, einem Netzwerk, das seit 2018 über 6.000 Unternehmen, 400 weiterführende Schulen und mehr als 800 Vereine im Großraum Hamburg vereint. Durch diese Kooperation bieten wir eine Plattform, die Kinder, Unternehmer und die Öffentlichkeit miteinander verbindet und ein nachhaltiges Netzwerk für zukünftige Fachkräfte schafft.

**Gemeinsam das Ziel erreichen**

Ihre Spende unterstützt eine wichtige Initiative, die 2025 mit dem Ziel startet, alle Schulkinder im Großraum Hamburg kostenfrei am Kunstkaleidoskop-Wettbewerb teilnehmen zu lassen. Tragen Sie dazu bei, dass Kinder ihre Kreativität entdecken, soziale Kompetenzen stärken und von einem Netzwerk gefördert werden, das Bildung und Wirtschaft auf einzigartige Weise verbindet.
      `,
    },
    unternehmen: {
      title: 'Für Unternehmer',
      content: `
Werden Sie Teil des Kunstkaleidoskop-Projekts und gestalten Sie die Zukunft kreativer Jugendförderung aktiv mit!

Das Kunstkaleidoskop-Projekt bietet Unternehmern eine einzigartige Möglichkeit, soziale Verantwortung zu übernehmen und gleichzeitig Ihre Marke und Werte auf kreative Weise zu präsentieren. Unterstützen Sie uns als Unternehmer und fördern Sie die Fachkräfte und kreativen Talente von morgen.

**Warum Unternehmer das Kunstkaleidoskop-Projekt unterstützen sollten**

Als Unternehmer haben Sie eine bedeutende Rolle in der Entwicklung junger Menschen. Durch Ihre Unterstützung des Kunstkaleidoskop-Projekts helfen Sie dabei, dass Schüler schon frühzeitig Einblicke in die Berufswelt gewinnen und wichtige soziale Kompetenzen erlernen. Gleichzeitig tragen Sie dazu bei, dass die Kreativität und das Selbstbewusstsein dieser Kinder gefördert wird – Fähigkeiten, die sie später zu wertvollen Fachkräften machen.

**Ihre Vorteile auf einen Blick:**

- **Positive Markenpräsenz:** Sie werden als Unterstützer einer innovativen Jugendförderung wahrgenommen, was Ihre Marke und Werte in der Öffentlichkeit stärkt.
- **Exklusiver Zugang zu jungen Talenten:** Sie erhalten die Möglichkeit, zukünftige Fachkräfte frühzeitig kennenzulernen und erste Kontakte zu potenziellen Praktikanten, Auszubildenden und späteren Mitarbeitern zu knüpfen.
- **Netzwerkaufbau und Kundenbindung:** Als Partner des Kunstkaleidoskop-Projekts bauen Sie wertvolle Netzwerke auf – sowohl mit anderen Unternehmern als auch mit den Familien und Schulen der teilnehmenden Kinder.

**Kunst, Kaffee & Mehr – Themen-Events für Unternehmer**

Die Kunst, Kaffee & Mehr-Events bieten Ihnen als Unternehmer eine ideale Plattform, um Ihre Marke und Ihre Produkte in einem inspirierenden und kreativen Umfeld zu präsentieren. Diese wöchentlichen Themenevents finden an wechselnden Orten statt und bieten eine einzigartige Gelegenheit, Ihr Unternehmen gezielt vorzustellen. Sie können ein Event selbst veranstalten und das Thema frei bestimmen – sei es als Jubiläumsfeier, Produkt-Promotion, Kunst-Event oder einfach als Anlass, Ihre soziale Verantwortung zu zeigen.

**Mögliche Themen für Ihre Veranstaltung:**

- **Jubiläum oder Meilenstein:** Feiern Sie einen besonderen Moment Ihres Unternehmens in einem kreativen Rahmen und machen Sie Ihre Kunden und Partner zu Teilhabern dieser Feier.
- **Produkt-Promotion:** Nutzen Sie das Event, um ein neues Produkt vorzustellen und Ihre Marke aktiv zu bewerben. Ihre Produkte und Dienstleistungen werden auf einzigartige Weise mit dem Event verbunden und bleiben so langfristig in Erinnerung.
- **Kunst und Kultur:** Falls Ihr Unternehmen eine Affinität zur Kunst hat, können Sie den künstlerischen Fokus des Events betonen und so eine Verbindung zwischen Ihrem Unternehmen und der kulturellen Förderung schaffen.
- **Vereinsunterstützung:** Sponsern Sie ein Event bei einem Verein Ihrer Wahl, sei es ein Sportverein oder eine gemeinnützige Organisation, und gestalten Sie eine Aktivität, die zu Ihnen und Ihren Werten passt.

Nutzen Sie die Kunst, Kaffee & Mehr-Events als Plattform für Ihre Markenkommunikation und zeigen Sie Ihr Engagement! Die Eventreihe erlaubt es Ihnen, Ihre Zielgruppe direkt anzusprechen und sich in einem kreativen, positiven Umfeld zu positionieren. Jedes Event ist eine Möglichkeit, Ihre Marke authentisch und nachhaltig zu präsentieren, während Sie gleichzeitig etwas für die Gesellschaft und die nächste Generation tun.

**Direkte Unterstützung und Spenden**

Falls Sie sich neben einem Event zusätzlich engagieren möchten, steht Ihnen jederzeit die Möglichkeit offen, das Kunstkaleidoskop-Projekt auch finanziell zu unterstützen. Mit dem Spenden-QR-Code oberhalb können Sie direkt einen Betrag Ihrer Wahl spenden, um das Projekt weiter voranzubringen und so Kindern die kostenfreie Teilnahme am Wettbewerb zu ermöglichen.

**Jetzt Partner werden**

Klicken Sie auf den „Unternehmer werden“-Button, um mehr darüber zu erfahren, wie Sie das Kunstkaleidoskop-Projekt durch ein eigenes Kunst, Kaffee & Mehr-Event unterstützen können. Reservieren Sie Ihren Termin, gestalten Sie ein Event nach Ihrem Wunsch und werden Sie Teil eines Projekts, das das Leben und die Zukunft vieler Kinder positiv prägt.

Gemeinsam schaffen wir echte Verbindungen und gestalten die kreative Zukunft – engagiert, authentisch und mit bleibendem Mehrwert!
      `,
    },
  };

  // Countdown renderer
  const countdownRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
        {days} Tage {hours} Stunden {minutes} Minuten {seconds} Sekunden
      </Typography>
    );
  };

  // Target date: January 1st, 2025, at 00:00 Uhr
  const targetDate = new Date('2025-01-01T00:00:00');

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: '20px',
          maxWidth: '1200px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom>
          Diese Seite befindet sich derzeit im Aufbau.
        </Typography>

        {/* Countdown */}
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
            Countdown zum Start vom Unterstützer Guide Hamburg
          </Typography>
          <Countdown date={targetDate} renderer={countdownRenderer} />
        </Box>

        {/* Buttons and QR Codes */}
        <Grid container spacing={{ xs: 2, md: 4 }} sx={{ marginTop: '20px' }}>
          {/* Für Bürger */}
          <Grid item xs={12} md={6}>
            <Card sx={{ textAlign: 'center' }}>
              <CardContent>
                <Typography variant="h5" fontSize={{ xs: '16px', md: '18px' }}>
                  Für Privatperson
                </Typography>
                {/* QR Code placeholder */}
                <Box
                  component="img"
                  src="/assets/paypalqr.png"
                  alt="QR Code für Bürger"
                  sx={{
                    width: { xs: '100px', md: '200px' },
                    height: { xs: '100px', md: '200px' },
                    margin: 'auto',
                  }}
                />
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button variant="text" onClick={() => handleMehrErfahren('buerger')}>
                  Mehr erfahren
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Für Unternehmen */}
          <Grid item xs={12} md={6}>
            <Card sx={{ textAlign: 'center' }}>
              <CardContent>
                <Typography variant="h5" fontSize={{ xs: '16px', md: '18px' }}>
                  Für Unternehmen
                </Typography>
                {/* QR Code placeholder */}
                <Box
                  component="img"
                  src="/assets/snookerfyqr.png"
                  alt="QR Code für Unternehmen"
                  sx={{
                    width: { xs: '100px', md: '200px' },
                    height: { xs: '100px', md: '200px' },
                    margin: 'auto',
                  }}
                />
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button variant="text" onClick={() => handleMehrErfahren('unternehmen')}>
                  Mehr erfahren
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        {/* Content Section */}
        {activeContent && (
          <Box sx={{ marginTop: '40px' }}>
            <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
              {contentData[activeContent].title}
            </Typography>
            <ReactMarkdown
              components={{
                p: ({ node, ...props }) => (
                  <Typography variant="body1" sx={{ fontSize: '1rem' }} paragraph {...props} />
                ),
                strong: ({ node, ...props }) => <strong {...props} />,
                em: ({ node, ...props }) => <em {...props} />,
                ul: ({ node, ...props }) => (
                  <List
                    sx={{
                      paddingLeft: '1em',
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                    {...props}
                  />
                ),
                li: ({ node, ...props }) => (
                  <ListItem
                    sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}
                    disableGutters
                    disablePadding
                  >
                    <Typography variant="body1" sx={{ fontSize: '1rem' }} component="span" {...props} />
                  </ListItem>
                ),
                h1: ({ node, ...props }) => (
                  <Typography variant="h5" align="center" fontWeight="bold" gutterBottom {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <Typography variant="h6" align="center" fontWeight="bold" gutterBottom {...props} />
                ),
              }}
            >
              {contentData[activeContent].content}
            </ReactMarkdown>
          </Box>
        )}

        {/* Map Section */}
        <Box sx={{ marginTop: '40px' }}>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Hier entsteht unser Unterstützer Guide
          </Typography>
          <iframe
            src="https://www.google.com/maps/d/u/5/embed?mid=1vz6rtkV3ksyy_6IzGSxCrqY-2RGoOvs&ehbc=2E312F&noprof=1&hl=de"
            width="100%"
            height="480"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Unterstützer Map"
          ></iframe>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default UnterstutzerPage;
