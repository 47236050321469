import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import KontoPage from "scenes/kontoPage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import WettbewerbPage from "scenes/wettbewerb";
import EventsPage from "scenes/events"
import UnterstuetzerPage from "scenes/unterstuetzer"
import AGBPage from "scenes/agb"
import ImpressumPage from "scenes/impressum";
import DatenschutzPage from "scenes/datenschutz";
import KontaktPage from "scenes/kontakt";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/einloggen" element={<LoginPage />} />
            <Route path="/profile/:userId" element={<ProfilePage />} />
            <Route
              path="/konto"
              element={isAuth ? <KontoPage /> : <Navigate to="/einloggen" />}
            />
            <Route path="/wettbewerb" element={<WettbewerbPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/unterstuetzer" element={<UnterstuetzerPage />} />
            <Route path="/kontakt" element={<KontaktPage />} />
            <Route path="/agb" element={<AGBPage />} />
            <Route path="/impressum" element={<ImpressumPage />} />
            <Route path="/datenschutz" element={<DatenschutzPage />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
